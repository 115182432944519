<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <ProductTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-row>
                        <v-col sm="3">Пол</v-col>
                        <v-col sm="9">{{ entity.gender.title }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="3">Название</v-col>
                        <v-col sm="9">{{ entity.title }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="3">Артикул</v-col>
                        <v-col sm="9">{{ entity.code }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="3">Цена</v-col>
                        <v-col sm="9">{{ priceFormated(entity.price) }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <span><b>Основная картинка</b></span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col xs="12" sm="6" md="4" lg="3" class="d-flex flex-column justify-space-between">
                            <v-img v-if="entity.picture" :src="imageSrc(entity.picture)" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex justify-space-between align-center">
                            <span><b>Дополнительные картинки</b></span>
                        </v-col>
                    </v-row>
                    <v-row>
                        <template v-for="(picture, index) in entity.pictures">
                            <v-col :key="`picture-${index}`" xs="12" sm="6" md="4" lg="3" class="d-flex flex-column justify-space-between">
                                <v-img v-if="picture" :src="imageSrc(picture)" />
                            </v-col>
                        </template>
                    </v-row>
                    <v-row>
                        <v-col sm="3">Описание</v-col>
                        <v-col sm="9" v-html="htmlFormatted(entity.description)"></v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="3">Состав</v-col>
                        <v-col sm="9" v-html="htmlFormatted(entity.composition)"></v-col>
                    </v-row>
                    <v-row>
                        <v-col sm="3">Сортировка</v-col>
                        <v-col sm="9">{{ entity.position }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Активен</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.enabled)}`">
                                &nbsp;{{entity.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >К списку</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import ProductTabLinks from '@/components/products/ProductTabLinks';

    import { imageSrc, priceFormated, htmlFormatted } from '../../helpers';

    export default {
        name: 'ProductDetail',

        components: {
            ProductTabLinks,
        },

        data() {
            return {
                imageSrc,
                priceFormated, 
                htmlFormatted,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('products', {
                entity: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
        },

        async mounted() {
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('products/get', {id});
            }
        },

        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                this.$router.push({ path: '/products' });
            },
        },
    };
</script>